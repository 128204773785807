import React from 'react';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import Layout from '../../components/layout';
import { Grid, Typography, Box } from '@material-ui/core';
import SEO from '../../components/seo';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import CustomLink from '../../components/custom-link';

const PartnerInnerPage = ({ pageContext: { _partner } }) => {
  return (
    <Layout>
      <SEO lang='en' title={_partner.title} />
      <SectionContainerLayoutWithFilter title='Global Network' isViewAll baseLink='/partners'>
        <Typography variant='h5' gutterBottom>
          {_partner.name}
        </Typography>
        <Grid container direction='row' spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <img
              src={`${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`}
              alt={_partner.title}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography paragraph color='textSecondary'>
              {_partner.description}
            </Typography>

            <Grid container xs={6}>
              {_partner.facebookUrl ? (
                <a href={_partner.facebookUrl} rel='noreferrer' target='_blank'>
                  <FacebookIcon color='primary' />
                </a>
              ) : null}
              {_partner.linkedinUrl ? (
                <a href={_partner.linkedinUrl} rel='noreferrer' target='_blank'>
                  <LinkedInIcon color='primary' />
                </a>
              ) : null}
              {_partner.instagramUrl ? (
                <a href={_partner.instagramUrl} rel='noreferrer' target='_blank'>
                  <InstagramIcon color='primary' />
                </a>
              ) : null}
              {_partner.twitterUrl ? (
                <a href={_partner.twitterUrl} rel='noreferrer' target='_blank'>
                  <TwitterIcon color='primary' />
                </a>
              ) : null}
            </Grid>

            {_partner.websiteUrl ? (
              <Box my={2}>
                <CustomLink external linkUrl={_partner.websiteUrl} linkText='GO TO WEBSITE' type='2' />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default PartnerInnerPage;
